@import '@client/styles/mixins.scss';

.popup {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 193px;
  height: auto;
  padding: 9px 10px;
  color: #122536;
  text-align: right;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.27);
  z-index: 10000;

  @include screen('mobile') {
    width: 254px;
    padding: 13px 12px;
  }

  .close-icon {
    position: absolute;
    top: 8px;
    left: 7px;
    width: 10px;
    height: 10px;
    cursor: pointer;

    @include screen('mobile') {
      width: 12px;
      height: 12px;
      top: 12px;
      left: 10px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    font-family: $main_font_bold;
    font-size: 22px;
    line-height: 1.3;
    padding-left: 15px;

    // @include screen('mobile') {
    //   font-size: 20px;
    // }
  }

  .voting-activity {
    font-family: $main_font_light;
    font-size: 10px;
    line-height: 1;
    color: #122536;
    display: flex;
    align-items: center;

    @include screen('mobile') {
      font-size: 13px;
    }

    .indicator {
      font-size: 8px;
      line-height: 0;
      padding-right: 4px;
      color: #d64e4d;

      @include screen('mobile') {
        font-size: 10px;
      }

      &.is-larger {
        color: #7fac67;
        .indicator-icon {
          transform: rotate(0);

          svg {
            g {
              fill: #7fac67;
            }
          }
        }
      }

      &-icon {
        width: 6px;
        height: 6px;
        margin-left: 2px;
        transform: rotate(180deg);

        @include screen('mobile') {
          width: 8px;
          height: 8px;
        }

        svg {
          width: 100%;
          height: 100%;
          g {
            fill: #d64e4d;
          }
        }
      }
    }
  }

  .parties-list {
    padding-top: 9px;

    @include screen('mobile') {
      padding-top: 15px;
    }

    .row {
      font-family: $main-font;
      font-size: 16px;
      padding-bottom: 6px;

      @include screen('mobile') {
        // font-size: 14px;
        padding-bottom: 12px;
      }

      .party-name {
        color: #122536;
        text-align: right;

        @include screen('mobile') {
          line-height: 1.1;
        }
      }

      .colored-block {
        height: 5px;
        background-color: currentColor;
        transition: width 0.3s ease-in-out;

        @include screen('mobile') {
          height: 7px;
        }
      }
    }
  }
}
