@import '@client/styles/mixins.scss';

.general-result {
  width: 100%;
  max-width: 495px;
  padding: 12px 0 24px;
  margin: 0 auto;

  @include screen('mobile'){
    max-width: 91%;
    padding: 17px 0 24px;
  }

  &.is-full-height {
    .more-handler{
      .icon-arrow-down {
        transform: rotate(180deg);
      }
    }
  }

  .list {
    height: 108px;
    overflow: hidden;
    transition: height 0.5s ease-in-out;

    .row {
      display: flex;
      font-family: $main-font_medium;
      font-size: 15px;

      $partyTextWidth: 120px;
      $partyTextWidthMobile: 105px;
      .party-name {
        position: relative;
        color: #122536;
        text-align: left;
        padding: 4px 0 2px 11px;
        width: $partyTextWidth;
        font-size: 15px;

        @include screen('mobile'){
          font-size: 14px;
          width: $partyTextWidthMobile;
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 1px;
          background-color: #979797;
        }
      }

      .visual-part {
        width: calc(100% - #{$partyTextWidth});
        display: flex;
        
        @include screen('mobile'){
          width: calc(100% - #{$partyTextWidthMobile});
        }
      }

      .colored-block {
        height: 19px;
        background-color: currentColor;
        margin-top: 4px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      .percent {
        padding: 2px 5px 0 0;
      }
    }
  }

  .more-handler {
    font-family: $main-font_medium;
    font-size: 13px;
    padding: 9px 0 0 8px;
    text-decoration: underline;
    cursor: pointer;

    .icon-arrow-down {
      width: 14px;
      height: 10px;
      background-size: contain;
      background-repeat: no-repeat;
      margin: 0 auto;
      transform: rotate(0);
      transition: all 0.2s 0.3s;
    }
  }
}