@import '@client/styles/mixins.scss';

.search {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 254px;
  padding: 7px 6px 8px;
  text-align: right;
  border-radius: 4px;
  background-color: white;
  scrollbar-color: #e2e2e2 transparent;
  scrollbar-width: thin !important;
  z-index: 1999;

  @include screen('mobile') {
    top: 14px;
    padding: 8px 6px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: 0;
    border-radius: 10px;
    border: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e2e2e2;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #e2e2e2;
  }

  .title {
    font-family: $main_font_bold;
    font-size: 20px;
    line-height: 1.2;
    color: #0d2b5f;
    padding-right: 3px;

    @include screen('mobile') {
      padding-bottom: 1px;
    }
  }

  .search-icon-btn {
    position: absolute;
    top: 7px;
    left: 0;
    margin: 0;
  }

  .input-wrap {
    position: relative;
    width: 100%;
    height: 100%;
  }

  input {
    width: 100%;
    height: 29px;
    color: #000;
    font-size: 13px;
    padding: 7px 5px 5px 40px;
    font-family: $main-font;
    border-radius: 4px;
    outline: 0;
    box-shadow: none;
    border: solid 1px #d0d0d3;
    background-color: #fff;

    @include screen('mobile') {
      font-size: 16px;
    }

    &:-webkit-input-placeholder {
      color: rgba(18, 37, 54, 0.5);
      opacity: 1;
    }

    &:-moz-placeholder {
      color: rgba(18, 37, 54, 0.5);
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: rgba(18, 37, 54, 0.5);
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: rgba(18, 37, 54, 0.5);
      opacity: 1;
    }

    &::placeholder {
      color: rgba(18, 37, 54, 0.5);
      opacity: 1;
    }
  }

  .suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border-radius: 4px;
    padding: 8px 4px 8px 8px;
    z-index: 2;
    transform: translate(0, -8px);
    box-shadow: 0 0 9px 0 rgba(57, 110, 228, 0.1);

    &-inner {
      max-height: 240px;
      width: 100%;
      background-color: white;
      overflow-y: auto;
    }

    .item {
      background-color: transparent;
      border-radius: 4px;
      padding: 4px 5px;
      margin-left: 5px;
      transition: background-color 0.2s ease-in;
      font-family: $main-font;
      font-size: 13px;
      color: #122536;
      cursor: pointer;

      &:hover {
        background-color: rgba(18, 37, 54, 0.07);
      }

      span {
        font-family: $main_font_medium;
      }
    }
  }
}
