@import '@client/styles/mixins.scss';

.header {
  background-color: #0D2B5F;
  width: 100%;
  padding: 5px;

  @include screen('mobile'){
    padding: 11px 0 13px;
  }

  .logo {
    max-width: 287px;

    @include screen('mobile'){
      max-width: 161px;
      margin: 0 auto;
    }
  }
}