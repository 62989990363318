@import '@client/styles/mixins.scss';

.leaflet-map {
  position: relative;
}

.map {
  width: 700px;
  height: 460px;
  background-color: #d8dedf;

  @include screen('mobile') {
    width: 100vw;
    max-width: 700px;
    height: 600px;
  }

  svg {
    path {
      transition: fill-opacity 0.3s;
      paint-order: stroke;

      &:hover {
        fill-opacity: 1;
      }
    }
  }
}

.path-country {
  stroke-opacity: 1;
  stroke-width: 2px;
}

.path-city {
  stroke-opacity: 0.75;
}
.region-label {
  stroke: white;
  fill: black;
  text-anchor: middle;
  dominant-baseline: hanging;
  stroke-width: 1px;
  paint-order: stroke;
  // font-size: 11px;
  font-family: 'Yonit Regular';
}

.creds {
  direction: ltr;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1000;
  font-size: 12px;
  color: black;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.65);

  a {
    color: #0d2b5f;
  }

  &-item {
    margin: 0 5px;
  }

  .divider {
    height: 15px;
    width: 1px;
    background-color: black;
  }
}
