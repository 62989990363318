@font-face {
	font-family: 'Yonit Regular';
	src: url('@client/assets/fonts/Yonit-Regular.woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Yonit Light';
	src: url('@client/assets/fonts/Yonit-Light.woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Yonit Medium';
	src: url('@client/assets/fonts/Yonit-Medium.woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Yonit Bold';
	src: url('@client/assets/fonts/Yonit-Bold.woff');
	font-weight: normal;
	font-style: normal;
}